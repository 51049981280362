import { CurrencyPipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import {
  IAccount,
  IAccountLowBalance,
  IAccountsResponseDetails,
  ILowBalance,
} from "@app/models/accounts";
import { AuthenticationService } from "@app/modules/shared/services/authentication.service";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { InputComponent } from "@app/modules/theme/components/input/input.component";
import { Languages } from "@app/store/actions/app.actions";
import { TranslateService } from "@ngx-translate/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable, Subject, of, throwError } from "rxjs";
import { catchError, take, takeUntil } from "rxjs/operators";
import { ILowBalanceToSave } from "../../models/low-balance";
import { LowBalanceNavigationService } from "../../services/low-balance-navigation-service.service";
import { LowBalanceService } from "../../services/low-balance.service";

@Component({
  selector: "fp-low-balance",
  templateUrl: "./low-balance.component.html",
  styleUrls: ["./low-balance.component.scss"],
})
export class LowBalanceComponent implements OnInit, OnDestroy {
  @ViewChild("inputAmount") inputAmount: InputComponent;
  @ViewChild("accountSelect") accountSelect: MatSelect;
  @ViewChild("tempMessageError", { static: true })
  tempMessageError: TemplateRef<any>;

  destroy$: Subject<boolean> = new Subject<boolean>();
  title: string = "general_title";
  subtitle: string = "general_subtitle";
  formLowBalance: UntypedFormGroup;
  lowBalanceValidationMessage: any;

  accounts: IAccountsResponseDetails[] = [];
  lowBalances: IAccountLowBalance[] = [];
  selectedAccountId: string;
  selectedLowBalance: IAccountLowBalance;
  selectedLowBalanceAmount: number;
  selectedEmail: string;
  errorMessage: string;

  constructor(
    private appFacade: AppFacade,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private commonsService: CommonsService,
    private currencyPipe: CurrencyPipe,
    private lowBalanceService: LowBalanceService,
    private lowBalanceNavigationService: LowBalanceNavigationService,
    private snackBar: MatSnackBar,
    public permissionService: NgxPermissionsService,
  ) {
    this.selectLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: Languages) => {
        if (value) {
          this.translate.use(value);
        }
      });
  }

  async ngOnInit(): Promise<any> {
    this.initForm();
    await this.getLowBalance();
    await this.getAccounts();
  }

  initForm(): void {
    this.formLowBalance = new UntypedFormGroup({
      amount: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9.$ ]+$"),
      ]),
      account: new UntypedFormControl("", [Validators.required]),
      email: new UntypedFormControl(
        {
          value: "",
        },
        [
          Validators.required,
          Validators.pattern(
            "^([a-zA-Z0-9-+_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$",
          ),
        ],
      ),
    });

    this.lowBalanceValidationMessage = {
      amount: [{ type: "required", message: "form_errors.required" }],
      email: [
        { type: "required", message: "form_errors.required" },
        { type: "pattern", message: "form_errors.email" },
      ],
    };
  }

  showError(message: string, duration: number = 5000): void {
    this.errorMessage = message;
    this.snackBar.openFromTemplate(this.tempMessageError, {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration,
    });
  }

  /**
   * Close Message
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }

  setCurrencyValue(amount: number) {
    // Usar 'symbol-narrow' para forzar que salga el signo $
    const formattedValue = this.currencyPipe.transform(
      amount,
      "COP",
      "symbol-narrow",
      "1.0-0",
    );
    this.formLowBalance.controls.amount.setValue(formattedValue);
    if (this.inputAmount) {
      this.inputAmount.writeValue(formattedValue);
    }
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === "Backspace") {
      setTimeout(() => {
        if (
          typeof this.formLowBalance.controls.amount.value !== "string" ||
          this.formLowBalance.controls.amount.value == ""
        )
          return;
        const x = Number(
          this.formLowBalance.controls.amount.value
            .replace(/[$\s]/g, "")
            .replace(/[,.]/g, "")
            .trim(),
        );
        this.inputAmount.onModelChange(x);
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      .pipe(take(1))
      .toPromise();
    if (promiseAccounts?.data === null) {
      this.appFacade.getAccounts(
        await this.authenticationService.getUsername(),
      );
      this.selectAccounts$.pipe(take(2)).subscribe((action: IAccount) => {
        if (action && action.data !== null) {
          this.accounts = action.data.filter(
            (x) => x.accountType && x.statusAccount === "Activo",
          );
          this.loadAccountDetails();
        }
        if (action && action.error) {
          this.commonsService.navigate("error");
        }
      });
    } else {
      this.accounts = promiseAccounts.data.filter((x) => x.accountType);
      this.loadAccountDetails();
    }
  }

  async getLowBalance(): Promise<any> {
    const promiseLowBalance = await this.selectLowBalance$
      .pipe(take(1))
      .toPromise();
    if (promiseLowBalance?.data === null) {
      this.appFacade.getLowBalance(
        await this.authenticationService.getUsername(),
      );
      this.selectLowBalance$.pipe(take(2)).subscribe((action: ILowBalance) => {
        if (action && action.data !== null) {
          this.lowBalances = action.data;
        }
      });
    } else {
      this.lowBalances = promiseLowBalance.data;
    }
  }

  async loadAccountDetails(): Promise<any> {
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();

    if (this.accounts.length > 0) {
      const selectedAccount = this.accounts.find(
        (account) => account.accountId === promiseAccountSelected?.accountId,
      );

      if (selectedAccount) {
        this.changeSelectedAccount(promiseAccountSelected?.accountId);
      } else {
        this.changeSelectedAccount(this.accounts[0].accountId);
      }
    } else {
      this.commonsService.navigate("error");
    }
  }

  changeSelectedAccount(accountId: string, event: any = null): void {
    const selectedAccount = this.accounts.find(
      (x) => x.accountId === accountId,
    );
    const selectedLowBalance = this.lowBalances.find(
      (x) => x.accountId === Number(accountId),
    );

    this.selectedLowBalance = selectedLowBalance;
    this.selectedAccountId = selectedAccount.accountId;
    this.formLowBalance.controls.account.setValue(selectedAccount.accountId);

    const lowBalanceRecharge = selectedLowBalance.lowBalanceRecharge;

    this.selectedLowBalanceAmount = lowBalanceRecharge;
    this.setCurrencyValue(lowBalanceRecharge);

    const email = selectedLowBalance.emailNotification;

    this.selectedEmail = email;

    this.formLowBalance.controls.email.setValue(email);

    if (event) {
      if (this.inputAmount) {
        this.inputAmount.updateInput();
      }
    } else {
      this.formLowBalance.controls.account.markAsTouched();
      this.formLowBalance.controls.account.markAsDirty();
    }
  }

  async saveLowBalance() {
    this.appFacade.setLoaderShow({
      type: "GENERAL",
    });
    const userName = (await this.authenticationService.getUsername()) + "";
    let amount = this.formLowBalance.controls.amount.value;
    if (typeof amount == "string") {
      amount = Number(
        this.formLowBalance.controls.amount.value
          .split("$")[1]
          .replace(/\./g, "")
          .trim(),
      );
    }

    let lowBalance: ILowBalanceToSave = {
      personId: userName.split("-")[0],
      accountId: Number(this.selectedAccountId),
      balanceLow: amount,
      emails: {
        notification: this.formLowBalance.controls.email.value,
      },
    };

    this.lowBalanceService
      .updateLowBalance(lowBalance)
      .pipe(
        takeUntil(this.destroy$),
        catchError((error: any) => {
          if (error.error.errorCode == "102215") {
            this.showError(error.error.message);
            return of();
          }
          return throwError(() => new Error(error));
        }),
      )
      .subscribe({
        next: (res) => {
          if (res && res.code == "102210") {
            const accountSelected = { ...this.selectedLowBalance };
            accountSelected.lowBalanceRecharge = lowBalance.balanceLow;
            accountSelected.emailNotification = lowBalance.emails.notification;
            this.appFacade.setLowBalanceSelected(accountSelected);
            this.appFacade.setLoaderHide();
            this.lowBalanceNavigationService.next(1);
          }
        },
        error: (err) => {
          this.appFacade.setLoaderHide();
          this.commonsService.navigate("error");
        },
        complete: () => {
          this.appFacade.setLoaderHide();
        },
      });
  }

  formValidator(): boolean {
    const requiredFields =
      this.formLowBalance.controls.amount.value &&
      this.formLowBalance.controls.email.value;
    const email = !(
      this.selectedEmail == this.formLowBalance.controls.email.value
    );
    return requiredFields && (this.changeValidator() || email);
  }

  changeValidator(): boolean {
    let change = false;
    let amount = this.formLowBalance.controls.amount.value;

    if (amount == "") {
      return true;
    } else if (typeof amount == "string") {
      // Elimina símbolo de dólar, espacios, comas y/o puntos
      amount = Number(
        this.formLowBalance.controls.amount.value
          .replace(/[$\s]/g, "")
          .replace(/[,.]/g, "")
          .trim(),
      );
      change = !(this.selectedLowBalanceAmount === amount);
    } else {
      change = !(this.selectedLowBalanceAmount === amount);
    }

    return change;
  }

  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectLowBalance$(): Observable<ILowBalance> {
    return this.appFacade.selectLowBalance$;
  }
}
