import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import {
  IAccount,
  IAccountLowBalance,
  IAccountsResponseDetails,
} from "@app/models/accounts";
import {
  CardTransactions,
  ViewCardTransaction,
} from "@app/modules/transactions/models/card-transactions";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { TransactionsService } from "@transactions/services/transactions.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Observable, Subject } from "rxjs";
import { take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "fp-card-transactions",
  templateUrl: "./card-transactions.component.html",
  styleUrls: ["./card-transactions.component.scss"],
})
export class CardTransactionsComponent implements OnInit {
  accountSelect: IAccountsResponseDetails;
  accountid: string;

  filters = {
    licensePlate: false,
    numberTag: false,
    infoAditional: false,
    state: false,
    acction: false,
  };

  transactionError: boolean = false;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    startPosition: 0,
    stagePadding: 20,
    margin: 10,
    navSpeed: 700,
    navText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
    center: false,
  };

  loading: boolean = true;
  lowBalanceSelected: IAccountLowBalance;
  lowBalance: IAccountLowBalance[];

  expand: boolean = true;

  formAccount: UntypedFormGroup;

  accounts: IAccountsResponseDetails[];
  destroy$: Subject<boolean> = new Subject<boolean>();

  lastEvaluatedKey: string;

  transactions: ViewCardTransaction[] = [];
  transactionsPaginator: ViewCardTransaction[][] = [];
  ipageActive: number = 1;
  pagesItems: number = 0;
  pages: number[] = [];
  filterLimit: number = 10;

  hasMoreData: boolean = true;

  accountIdSelected: string;

  constructor(
    private appFacade: AppFacade,
    private authenticationService: AuthenticationService,
    private commonsService: CommonsService,
    private route: ActivatedRoute,
    private transactionsService: TransactionsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.getAccounts();
    await this.setSelectedAccount();
  }

  initForm(): void {
    this.formAccount = new UntypedFormGroup({
      account: new UntypedFormControl("", [Validators.required]),
    });
  }

  /**
   * get accounts info
   */
  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      .pipe(take(1))
      .toPromise();
    if (promiseAccounts.data === null) {
      this.appFacade.getAccounts(
        await this.authenticationService.getUsername(),
      );
      this.selectAccounts2$
        .pipe(takeUntil(this.destroy$))
        .subscribe((action: IAccount) => {
          if (action && action.data !== null && action.data.length > 0) {
            this.accounts = action.data.filter(
              (x) => x.accountType && x.statusAccount === "Activo",
            );
          } else if (action && action.error) {
            this.commonsService.navigate("/error");
          }
        });
    }
    if (promiseAccounts.data !== null) {
      this.accounts = promiseAccounts.data.filter(
        (x) => x.accountType && x.statusAccount === "Activo",
      );
    }
    if (this.accounts && this.accounts.length > 0) {
      const account = this.accounts[0];
      this.formAccount.controls.account.setValue(account);
    }
  }

  async setSelectedAccount(): Promise<any> {
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();
    if (promiseAccountSelected) {
      const data = {
        account: promiseAccountSelected,
        flag: true,
      };
      this.chooseAccount(data);
    } else if (this.accounts?.length > 0) {
      const data = {
        account: this.accounts[0],
        flag: true,
      };
      this.chooseAccount(data);
    }
  }

  async getTransactions(accountId: string) {
    this.hasMoreData = true;
    this.loading = true;
    this.transactionError = false;
    const request = await this.transactionsService.getTransactionsByStatus({
      limit: 11,
      account: accountId,
      key: this.lastEvaluatedKey,
    });
    request.pipe(takeUntil(this.destroy$)).subscribe(
      (response: any) => {
        if (response.data) {
          this.loading = false;
          let cardTransactions: CardTransactions = response.data as CardTransactions;

          this.lastEvaluatedKey = cardTransactions.paging.lastEvaluatedKey;

          if (!this.lastEvaluatedKey) {
            this.hasMoreData = false;
          }

          const newTransactions = cardTransactions.results.map(
            (transaction) => {
              return {
                transactionType: transaction.title,
                confirmedDateFormatted: transaction.confirmedDateFormatted,
                paymentMethod: transaction.paymentMethod,
                statusView: transaction.paymentStatus,
                totalAmount: transaction.totalAmount,
                brand: transaction.brand,
                cardMask: transaction.maskedCardNumber,
              } as ViewCardTransaction;
            },
          );

          this.transactions = [...this.transactions, ...newTransactions];

          this.generatorPages();
        }
      },
      (error: any) => {
        this.loading = false;
        this.transactionError = true;
      },
    );
  }

  loadMore() {
    if (this.hasMoreData) {
      this.getTransactions(this.accountSelect.accountId);
    }
  }

  changeFilterLimit($event): void {
    this.filterLimit = $event;
    this.generatorPages();
  }

  /**
   * Chose account selected
   */
  chooseAccount(data: {
    account: IAccountsResponseDetails;
    flag: boolean;
  }): void {
    if (this.accountIdSelected === data.account.accountId) {
      return;
    }
    this.lastEvaluatedKey = "";
    this.formAccount.controls.account.setValue(data.account.accountId);
    this.accountIdSelected = data.account.accountId;
    this.accountSelect = { ...data.account };
    this.appFacade.setAccountSelected(data.account);

    this.transactions = [];
    this.getTransactions(this.accountSelect.accountId);
  }

  getStatusCategory(status: string): string {
    const successStatuses = ["approved", "confirmed"];
    const rejectedStatuses = ["rejected", "expired", "abandoned", "failed"];
    const pendingStatuses = [
      "created",
      "pending",
      "pendingProvider",
      "onProcess",
      "unconfirmed",
    ];

    if (successStatuses.includes(status)) {
      return "approved";
    } else if (rejectedStatuses.includes(status)) {
      return "rejected";
    } else if (pendingStatuses.includes(status)) {
      return "pending";
    }

    return "unknown";
  }

  prev(): void {
    if (this.ipageActive > 1) {
      this.ipageActive--;
    }
  }

  next(): void {
    if (this.ipageActive < this.pagesItems) {
      this.ipageActive++;
    }
  }

  changeIndexPage(ipage: number): void {
    if (ipage >= 1 && ipage <= this.pagesItems) {
      this.ipageActive = ipage;
    }
  }

  generatorPages(): void {
    this.pagesItems = Math.ceil(this.transactions.length / this.filterLimit);
    this.transactionsPaginator = [];
    for (let index = 0; index < this.pagesItems; index++) {
      const pageTransactions = this.transactions.slice(
        index * this.filterLimit,
        index * this.filterLimit + this.filterLimit,
      );
      this.transactionsPaginator.push(pageTransactions);
    }

    this.pages = [];
    for (let i = 1; i <= this.pagesItems; i++) {
      this.pages.push(i);
    }

    this.ipageActive = 1;
  }

  /**
   * get accounts from Selector
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  /**
   * get accounts from Selector
   */
  get selectAccounts2$(): Observable<IAccount> {
    return this.appFacade.selectAccounts2$;
  }

  /**
   * get selectedAccount from selector
   */
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  goBack(): void {
    this.commonsService.navigate("/home");
  }
}
