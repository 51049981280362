import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "fp-gou-redirect-modal",
  templateUrl: "./gou-redirect-modal.component.html",
  styleUrls: ["./gou-redirect-modal.component.scss"],
})
export class GouRedirectModalComponent {
  @Output() itemClickActive = new EventEmitter<{}>();
  @Input() title: string;
  @Input() description: string;
  @Input() btnMessage: string;

  modalAction(event: any) {
    this.itemClickActive.emit(event);
  }
}
