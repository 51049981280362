<fp-modal [typeModal]="'custom'" id="fp-gou-redirect-modal" (actionModal)="modalAction($event)"
    [btnAccept]="'Entendido'" [btnInfo]="btnMessage">
    <ng-template>
        <div class="modal-content">
            <h1 class="fp-h1 fp-payment-method-title">
                {{ title }}
            </h1>
            <p class="fp-gou-modal-description">
                {{description}}
            </p>
            <div class="img-container">

                <img class="fp-gou-modal-img" src="assets/images/gou/gou-aval.svg" alt="Pasarela de pago gou - aval">
            </div>
        </div>
    </ng-template>
</fp-modal>