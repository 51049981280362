import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageEvent } from "@app/helpers/utag.helpers";
import { WindowToken } from "@app/helpers/windows.helpers";
import { IAccount, IAccountsResponseDetails } from "@app/models/accounts";
import { ICustomerInfo } from "@app/models/customer-info";
import { IUserInfo, IUserInfoDataResult } from "@app/models/user-info";
import { IAutomaticRecharge } from "@app/modules/account/models/automatic-recharge.interface";
import { AccountNavigationService } from "@app/modules/account/services/account-navigation-service.service";
import { RouteTrackingService } from "@app/modules/account/services/route-tracking/route-tracking.service";
import { AccountFacade } from "@app/modules/account/store/facade/account.facade";
import { environment } from "@environment";
import { IEntity } from "@postpaid/models/entity";
import {
  IAccountEntity,
  IServiceRecharge,
} from "@postpaid/models/recharge-account";
import { AuthenticationService } from "@shared/services/authentication.service";
import { CommonsService } from "@shared/services/commons.service";
import { NotifyService } from "@shared/services/notify.service";
import {
  IFinancialProducts,
  IMinimumAccount,
} from "@shared-financial-data/models/financial-data";
import { SharedFinancialDataFacade } from "@shared-financial-data/store/facade/shared-financial-data.facade";
import { ITransactionRequest } from "@tag/models/recharge";
import { PseService } from "@tag/services/pse.service";
import { InputComponent } from "@theme/components/input/input.component";
import { PaymentComponent } from "@theme/components/payment/payment.component";
import { SnackMessageOptions } from "@theme/components/snack-message/snack-message.component";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import {
  ICreateTransaction,
  ICreateTransactionRequest,
} from "../../models/transaction.interface";
import { TransactionsService } from "../../services/transactions.service";
import { TransactionFacade } from "../../store/facade/transaction.facade";
import { IPospaidRechargeAccount } from "../../store/state/statusTransaction.state";
/**
 * Recharge Component
 */
@Component({
  selector: "fp-recharge-transaction",
  templateUrl: "./recharge.component.html",
  styleUrls: ["./recharge.component.scss"],
})
export class RechargeComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpRechargeTransaction = "fp-recharge-transaction";
  @ViewChild("reminder", { static: true }) tReminder: TemplateRef<any>;
  @ViewChild("notify", { static: true }) notify: TemplateRef<any>;
  @ViewChild("changeModality", { static: true })
  notifyChangeModality: TemplateRef<any>;
  @ViewChild("inputAmount") inputAmount: InputComponent;
  formTopUp: UntypedFormGroup;
  minAmount: number = Number(environment.pse.minimumAmount);
  typeMessage: SnackMessageOptions = "infoOnlyDescription";
  TopUpErrorMessages = {
    error: [
      { type: "required", message: "form_errors.field.required" },
      { type: "pattern", message: "form_errors.field.pattern" },
      { type: "amountInvalid", message: "form_errors.field.min_amount" },
    ],
  };
  formPaymentInvalid: boolean = true;
  postpaidFormInvalid: boolean = true;
  accounts: IAccountsResponseDetails[] = [];
  @ViewChild(PaymentComponent)
  paymentComponent: PaymentComponent;
  @ViewChild("accountSelect") accountSelect: MatSelect;
  destroy$: Subject<boolean> = new Subject<boolean>();
  entity: IAccountEntity = null;
  availableEntities: IEntity[] = [];
  selectedAccountType: string;
  selectedAccountId: string;
  selectedAccount: IAccountsResponseDetails;
  entitySelected: string;
  viewMessageError: boolean;
  timeOut: any;
  minimumAmount: any;
  clientId: string = "";
  userId: string = "";
  title: string = "";
  topUp: string = "";
  time: string = "";
  minAmountPos: any = {};
  minAmountPre: any = {};
  temporalTransactionObject: any = {};
  errorCode: string = "";
  pendingTransactionMessage: string = "";
  showBannerAutomaticRecharge: boolean = false;
  allAccountsPRE: boolean = false;
  creatingTransaction: boolean = false;

  /**
   * Construtor
   * @param snackBar MatSnackBar
   * @param storageService StorageService
   * @param appFacade AppFacade
   * @param window Window
   * @param pseService PseService
   */
  constructor(
    private snackBar: MatSnackBar,
    private appFacade: AppFacade,
    @Inject(WindowToken) private window: Window,
    private pseService: PseService,
    private authenticationService: AuthenticationService,
    private commonsService: CommonsService,
    private sharedFinancialFacade: SharedFinancialDataFacade,
    private transactionFacade: TransactionFacade,
    private notifyService: NotifyService,
    private accountNavigationService: AccountNavigationService,
    private accountFacade: AccountFacade,
    private trackingService: RouteTrackingService,
    private transactionsService: TransactionsService,
    private router: Router,
  ) {}

  /**
   * Init state to component
   */
  async ngOnInit(): Promise<any> {
    sessionStorage.removeItem("customToken");
    this.showBannerAutomaticRecharge = JSON.parse(
      sessionStorage.getItem("publicKey"),
    )?.config?.recurrent;
    this.initForm();
    this.loadUserInfo();
    this.loadCustomerInfo();
    await this.loadMinimumAmountPrepaid();
    await this.getAccounts();
    const automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
    if (
      !automaticRechargeData?.fromRecharge &&
      this.selectedAccountType === "PRE-REC" &&
      this.trackingService.getPreviousUrl() === "/account/select-modality"
    ) {
      this.notifyService.showNotify(this.notifyChangeModality, "", null);
      this.selectedAccountType = "PRE";
      setTimeout(() => {
        this.closeMessage();
      }, 5000);
    }
  }

  /**
   * Close Message
   */
  closeAlertActive(): void {
    this.notifyService.closeNotify();
  }

  initForm(): void {
    this.formTopUp = new UntypedFormGroup({
      amount: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9.$ ]+$"),
        Validators.minLength(7),
      ]),
      account: new UntypedFormControl("", [Validators.required]),
    });
  }
  /**
   * Destroy component
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.snackBar.dismiss();
  }
  /**
   * Selection Bank Change
   * @param entitySelected Entity Selected
   */
  onChangeBank(entitySelected: string) {
    this.entitySelected = entitySelected;
  }
  /**
   * Handel enable button form
   * @param $event Status form payment
   */
  enableBtn($event: boolean) {
    this.formPaymentInvalid = !$event;
  }

  /**
   * Create Transaction Recharge
   */
  async createTransaction(): Promise<void> {
    pageEvent(
      "click-btn-continue-recharge-transaction",
      "/transactions/recharge",
      "Init recharge transaction",
    );
    const formattedAmount = this.formTopUp.controls.amount.value
      .replace(/\./g, "")
      .replace("$ ", "");

    const account = this.formTopUp.controls.account.value;

    const transactionPayload: ICreateTransactionRequest = {
      totalAmount: Number(formattedAmount),
      transactionType: "recarga facilpass",
      transactionTypeData: {
        personId: this.clientId,
        account,
      },
    };

    try {
      this.appFacade.getCities();
      this.creatingTransaction = true;
      const transaction = await this.transactionsService.createTransaction(
        transactionPayload,
      );
      transaction.pipe(takeUntil(this.destroy$)).subscribe({
        next: ({ data }: { data: ICreateTransaction }) => {
          if (data) {
            sessionStorage.setItem("transactionDetail", JSON.stringify(data));
            this.commonsService.navigate("/transactions/unique-recharge");
          }
        },
      });
    } catch (error) {
      this.creatingTransaction = false;
    }
  }

  async transactionPSE(): Promise<any> {
    try {
      const accountId = this.formTopUp.controls.account.value;
      const infoTransaction: ITransactionRequest = {
        infoTransaction: {
          personTypeId: this.paymentComponent.formPurchaseDetail.controls
            .personType.value,
          amount: Number(
            this.formTopUp.controls.amount.value
              .split("$")[1]
              .replace(/\./g, "")
              .trim(),
          ),
          bankDescription: this.entitySelected,
          bankId: this.paymentComponent.formPurchaseDetail.controls.entities
            .value,
          accountId,
          transactionTypeId: "2",
        },
      };

      await this.authenticationService.refreshToken();
      const respTransaction = await this.pseService
        .postTransaction(infoTransaction)
        .toPromise();

      if (respTransaction.data.resultMessage.code === "100260") {
        this.window.location.href =
          respTransaction.data.infoTransaction.urlResponse;
      } else {
        this.appFacade.setLoaderHide();
      }
    } catch (error) {
      if (
        error.error &&
        error.error.data &&
        error.error.data.code == "100288"
      ) {
        this.pendingTransactionMessage = error.error.data.message;
      }
      this.appFacade.setLoaderHide();
      this.snackBar.openFromTemplate(this.notify, {
        horizontalPosition: "center",
        verticalPosition: "top",
        duration: 4000,
      });
    }
  }

  transactionPostpaid(): void {
    const formattedAmount = this.formTopUp.controls.amount.value
      .replace(/\./g, "")
      .replace("$ ", "");
    const rechargeData: IServiceRecharge = {
      personId: this.clientId,
      accountId: this.selectedAccountId,
      amount: Number(formattedAmount),
    };
    this.transactionFacade.getRechargeAccount(rechargeData);
    this.temporalTransactionObject = JSON.parse(JSON.stringify(rechargeData));
    this.temporalTransactionObject.name = this.entity?.name;
    localStorage.setItem(
      "temporalTransactionObject",
      JSON.stringify(this.temporalTransactionObject),
    );
    this.selectRechargePostpaidAccount$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IPospaidRechargeAccount) => {
        if (action && action.data !== null) {
          this.commonsService.navigate("/transactions/status");
          this.appFacade.setLoaderHide();
        }
        if (action && action.error !== null) {
          if (action.error.error.code === "100618") {
            this.commonsService.navigate("/transactions/status");
            this.appFacade.setLoaderHide();
          } else {
            this.errorCode = action.error.error.code;
            this.snackBar.openFromTemplate(this.notify, {
              horizontalPosition: "center",
              verticalPosition: "top",
              duration: 4000,
            });
          }

          this.appFacade.setLoaderHide();
        }
      });
  }

  /**
   * Hide notifycation
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }
  /**
   * Function to validate Amount
   * @param val value to check with minimun amount
   */
  validateAmount(val: string): void {
    if (!val) {
      this.formTopUp.controls.amount.setErrors({ required: true });
      return;
    }
    let cleanVal = val.split("$")[1];
    if (cleanVal?.length > 1) {
      cleanVal = cleanVal.replace(/\./g, "");
      if (Number(cleanVal) < this.minAmount) {
        this.formTopUp.controls.amount.setErrors({ amountInvalid: true });
        this.formTopUp.controls.amount.markAsTouched();
        this.formTopUp.controls.amount.markAsDirty();
        pageEvent(
          "minimun-amount-error",
          "/transactions/recharge",
          "error-monto-minimo",
        );
      } else {
        this.viewMessageError = false;
      }
      this.inputAmount.updateInput();
    } else {
      this.formTopUp.controls.amount.setErrors({ pattern: true });
    }
  }
  /**
   * Function to validate Amount to buy Tag
   * @param val value to check with minimun amount
   */
  validateAmountTag(val: string): void {
    if (!val) {
      return;
    }
    let cleanVal = val.trim().split("$")[1];
    if (cleanVal?.length > 1) {
      cleanVal = cleanVal.replace(/\./g, "");
      Number(cleanVal) < this.minAmount
        ? (this.viewMessageError = true)
        : (this.viewMessageError = false);
    }
  }

  /**
   * get accounts info
   */
  async getAccounts(): Promise<any> {
    const promiseAccounts = await this.selectAccounts$
      .pipe(take(1))
      .toPromise();
    if (promiseAccounts?.data === null) {
      this.appFacade.getAccounts(
        await this.authenticationService.getUsername(),
      );
      this.selectAccounts$.pipe(take(2)).subscribe((action: IAccount) => {
        if (action && action.data !== null && action.data.length > 0) {
          this.accounts = action.data.filter(
            (x) => x.accountType && x.statusAccount === "Activo",
          );
          this.allAccountsPRE = this.accounts.every(
            (account) => account.accountType === "PRE",
          );
          this.loadAccountDetails();
        }
        if (action && action.error) {
          this.commonsService.navigate("error");
        }
      });
    } else {
      this.accounts = promiseAccounts.data.filter((x) => x.accountType);
      this.allAccountsPRE = this.accounts.every(
        (account) => account.accountType === "PRE",
      );
      this.loadAccountDetails();
    }
  }

  async loadAccountDetails(): Promise<any> {
    const promiseAccountSelected = await this.selectAccountSelected$
      .pipe(take(1))
      .toPromise();

    if (this.accounts.length > 0) {
      const selectedAccount = this.accounts.find(
        (account) => account.accountId === promiseAccountSelected?.accountId,
      );

      if (selectedAccount) {
        this.changeSelectedAccount(promiseAccountSelected?.accountId);
      } else {
        this.changeSelectedAccount(this.accounts[0].accountId);
      }
      this.loadFinancialProducts();
    } else {
      this.commonsService.navigate("error");
    }
  }

  async loadMinimumAmountPrepaid(): Promise<any> {
    const promiseMinPrepaid = await this.selectMinimumAmountPrepaid$
      .pipe(take(1))
      .toPromise();
    if (promiseMinPrepaid?.data === null) {
      this.sharedFinancialFacade.getMinimumAmountPrepaid({
        modality: "PREPAGO",
        personType: "PERSONA_JURIDICA",
      });
      this.selectMinimumAmountPrepaid2$.pipe(take(2)).subscribe((value) => {
        if (value && value?.data !== null) {
          const serviceInfo = value.data.filter(
            (x) => x.convenio === "4023",
          )[0];
          if (serviceInfo) {
            this.loadAmountValues(serviceInfo, "PRE");
          } else {
            this.setMinAmountInfo();
          }
        }
        if (value.error !== null) {
          this.setMinAmountInfo();
        }
      });
    } else {
      const serviceInfo = promiseMinPrepaid.data.filter(
        (x) => x.convenio === "4023",
      )[0];
      if (serviceInfo) {
        this.loadAmountValues(serviceInfo, "PRE");
      } else {
        this.setMinAmountInfo();
      }
    }
  }

  loadAmountValues(serviceInfo: any, type: string) {
    const amount = Number(serviceInfo.montoMinimo);
    const time =
      serviceInfo.tiempoActualizacionPeajes > 1
        ? `${serviceInfo.tiempoActualizacionPeajes} horas`
        : `${serviceInfo.tiempoActualizacionPeajes} hora`;
    this.time = this.minAmountPre.time = time;
    this.minAmount = this.minAmountPre.amount = amount;
  }

  setMinAmountInfo(type: string = null): void {
    if (type) {
      this.time = this.minAmountPre.time;
      this.minAmount = this.minAmountPre.amount;
    } else {
      this.time = this.minAmountPos.time = this.minAmountPre.time = "1 hora";
      this.minAmount = this.minAmountPos.amount = this.minAmountPre.amount = 30000;
    }
    this.showUpdateTollTimeMessage();
  }

  showUpdateTollTimeMessage() {
    this.notifyService.showNotify(this.tReminder, "", null);
    setTimeout(() => {
      this.closeAlertActive();
    }, 5000);
  }

  async loadFinancialProducts(): Promise<void> {
    const promiseFinancialProducts = await this.selectFinancialProducts$
      .pipe(take(1))
      .toPromise();
    if (promiseFinancialProducts?.data === null) {
      this.sharedFinancialFacade.getFinancialProducts();
      this.selectFinancialProducts2$.pipe(take(2)).subscribe((entities) => {
        if (entities && entities.data !== null) {
          this.availableEntities = entities.data;
        } else if (entities.error !== null) {
          this.commonsService.navigate("error");
        }
      });
    } else {
      this.availableEntities = promiseFinancialProducts?.data;
    }
  }

  loadCustomerInfo(): void {
    this.selectCustomerInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: ICustomerInfo) => {
        if (action && action.data !== null) {
          this.clientId = `${action.data?.idType}${action.data?.idNumber}`;
        }
        if (action && action.error) {
          this.commonsService.navigate("error");
        }
      });
  }

  loadUserInfo(): void {
    this.selectUserInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IUserInfo) => {
        if (action && action.data !== null) {
          const userInfo: IUserInfoDataResult = action.data;
          this.userId = userInfo.userClientInfo[0].userId;
        }
        if (action && action.error) {
          this.commonsService.navigate("error");
        }
      });
  }

  changeSelectedAccount(accountId: string, event: any = null): void {
    const selectedAccount = this.accounts.find(
      (x) => x.accountId === accountId,
    );
    this.selectedAccountType = selectedAccount.accountType; //
    this.selectedAccountId = selectedAccount.accountId;
    this.selectedAccount = selectedAccount;
    this.setSelectedAccount(selectedAccount);
    this.setRechargeText();
    this.setMinAmountInfo(this.selectedAccountType);
    this.formTopUp.controls.account.setValue(selectedAccount.accountId);

    if (event) {
      this.formTopUp.controls.amount.setValue("");
      if (this.inputAmount) {
        this.inputAmount.updateInput();
      }
    } else {
      this.formTopUp.controls.account.markAsTouched();
      this.formTopUp.controls.account.markAsDirty();
    }
  }

  setRechargeText() {
    this.title = "title_" + this.selectedAccountType;
    this.topUp = "top_up_" + this.selectedAccountType;
  }

  setSelectedAccount(account: IAccountsResponseDetails): void {
    this.appFacade.setAccountSelected(account);
  }

  /**
   * Go automatic recharge config page
   */
  async goAutomaticRechargeConfig() {
    await this.setAutomaticRechargeData();
    this.accountNavigationService.next(0);
  }

  /**
   * Set automatic recharge data
   */
  async setAutomaticRechargeData() {
    const automaticRechargeData = await this.selectAutomaticRecharge$
      .pipe(take(1))
      .toPromise();
    this.accountFacade.setAutomaticRecharge({
      ...automaticRechargeData,
      rechargeValue: automaticRechargeData?.rechargeValue ?? null,
      activeRechargeValue: automaticRechargeData?.activeRechargeValue ?? null,
      email: automaticRechargeData?.email ?? null,
      fromRecharge: true,
    });
  }
  get selectAccountSelected$(): Observable<IAccountsResponseDetails> {
    return this.appFacade.selectAccountSelected$;
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
  get selectFinancialProducts$(): Observable<IFinancialProducts> {
    return this.sharedFinancialFacade.selectFinancialProducts$;
  }

  get selectFinancialProducts2$(): Observable<IFinancialProducts> {
    return this.sharedFinancialFacade.selectFinancialProducts2$;
  }

  get selectMinimumAmountPospaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPospaid$;
  }

  get selectMinimumAmountPospaid2$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPospaid2$;
  }

  get selectMinimumAmountPrepaid$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPrepaid$;
  }

  get selectMinimumAmountPrepaid2$(): Observable<IMinimumAccount> {
    return this.sharedFinancialFacade.selectMinimumAmountPrepaid2$;
  }

  get selectCustomerInfo$(): Observable<ICustomerInfo> {
    return this.appFacade.selectCustomerInfo$;
  }

  get selectUserInfo$(): Observable<IUserInfo> {
    return this.appFacade.selectUserInfo$;
  }

  get selectRechargePostpaidAccount$(): Observable<IPospaidRechargeAccount> {
    return this.transactionFacade.selectPospaidRechargeAccount$;
  }

  /**
   * Select automatic recharge data
   * @readonly
   * @type {Observable<IAutomaticRecharge>}
   */
  get selectAutomaticRecharge$(): Observable<IAutomaticRecharge> {
    return this.accountFacade.selectAutomaticRecharge$;
  }
}
