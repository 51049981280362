<ng-container *ngIf="account">
    <div class="accounts-card" [class.small]="small" [class.select]="select" (click)="chooseAccount(account,false)"
        *ngIf="account.statusAccount !=='Cancelada' && account.statusAccount !=='Anulada'">
        <div class="accounts-card-mask" *ngIf=" account.statusAccount === 'Creada' || currentStatus === '18022'">
            <div class="bg-mask">
            </div>
            <div class="container-mask-message">
                <fp-info-message
                  [message]="'Se activará, una vez los documentos sean aprobados en su totalidad.'"
                  typeClass="info"
                  [showClose]="false"
                  [small]="small">
                </fp-info-message>
            </div>
        </div>
        <div class="accounts-header-card">
            <ng-container *ngxPermissionsOnly="['CUESEL', 'CUEMOD']">
                <button *ngIf="template === 'templateOne' && account.accountType" class="fp-btn-white card-custom-btn"
                (click)="chooseAccount(account, true)" id="admin-account-{{account.accountId}}">
                    <img src="assets/images/home/accounts/settings.svg" alt="icono settings">
                </button>
            </ng-container>
            <div class="text-header-card" [class.m-none]="template !=='templateOne'">
                <p>{{ account.accountType ? 'Cuenta con recarga' : 'Cuenta' }}
                  {{ (account.accountType && account.accountType === 'PRE-REC' ? 'automatica' : (account.accountType &&
                  account.accountType !== 'PRE-REC'? 'prepago': '')) | translate }}
                  No. <strong>{{account.accountId}}</strong></p>
                <p>{{ 'nure' | translate }} <strong>{{ account.nure }} </strong>
                  <img
                    src="assets/images/common/blue-question.svg"
                    alt="Icon tooltip"
                    fpTooltip
                    [tooltip]="'Código que identifica a su empresa en FacilPass'"
                    tooltipPosition="center">
                </p>
            </div>
            <fp-radiobutton [formControl]="formAccount.controls['account']" [name]="account.accountId"
                class="fp-radio-vehicle" inputId="account{{account.accountId}}" [value]="account.accountId">
            </fp-radiobutton>
        </div>
        <div
          class="accounts-body-card" *ngxPermissionsOnly="['CUESALDO']"
          [ngClass]="{
            'expand': template ==='templateOne' && expand,
            'expand-automatic': template ==='templateOne' && expand && account.accountType === 'PRE-REC',
            'expand-small': template !== 'templateOne' && expand
            }">
          <div class="colum left">
              <span class="title-balance">{{ 'Saldo' | translate }}</span>
              <span class="detail-balance">${{ account.balance | currency : 'COP' : '' :
                  '1.0-0'}}</span>
          </div>
          <div class="row mt-1" *ngIf="template==='templateOne'">
              <div class="detail-content">
                  <span class="title-balance">{{ 'lowBalance' | translate }}</span>
                  <span class="detail-balance small">
                    ${{ account.balanceLow | currency : 'COP' : '' : '1.0-0' }}
                  </span>
              </div>
              <div class="detail-content m-left">
                  <span class="title-balance">
                    {{ 'minimumBalance' | translate }}
                    <img
                      src="assets/images/common/blue-question.svg"
                      alt="Icon tooltip"
                      class="title-balance__icon"
                      fpTooltip
                      [tooltip]="'basedOnTheTollOfTheHighestCategoryVehicleInYourFleet'"
                      tooltipPosition="center">
                  </span>
                  <span class="detail-balance small">
                    ${{ account.balanceMin | currency : 'COP' : '' : '1.0-0' }}
                  </span>
              </div>
          </div>
          <div class="automatic-recharge-section" *ngIf="account.accountType === 'PRE-REC'" [ngClass]="{'automatic-recharge-section--blocked': status === 'blocked'}">
            <h4 class="automatic-recharge-section__title">
              {{ 'automaticRecharge' | translate }}
              <span class="badge-blocked" *ngIf="status === 'blocked'">{{ 'blockedService' | translate }}</span>
            </h4>
            <div class="automatic-recharge-content">
              <div class="automatic-recharge-info">
                <div class="automatic-recharge-info__title">{{ 'rechargeValue' | translate }}</div>
                <div class="automatic-recharge-info__value">
                  ${{ account.financialProduct.rechargeBalance | currency : 'COP' : '' : '1.0-0' }}
                </div>
              </div>
              <div class="automatic-recharge-info">
                <div class="automatic-recharge-info__title">{{ 'balanceActivateRecharge' | translate }}</div>
                <div class="automatic-recharge-info__value">
                  ${{ account.financialProduct.lowBalance | currency : 'COP' : '' : '1.0-0' }}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</ng-container>
